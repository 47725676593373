
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'SectionHeadlineWithTextAndYoutubeVideo',
    components: {
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [ScreenSize],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      device: {
        type: String,
        required: false,
        default: 'mobile',
      },
    },
    serverCacheKey: function (props) {
      return (
        'section-headline-with-text-and-youtube-video-' +
        props.section.id +
        '-' +
        props.device
      );
    },
    data() {
      return {
        sizeOfVideoColumn: 0,
        isVideoLoaded: false,
        isLoaded: false,
      };
    },
    computed: {
      customWidth() {
        const percentage = (this.sizeOfVideoColumn / 12) * 100;
        return this.$vuetify.breakpoint.width <=
          this.$vuetify.breakpoint.thresholds.sm
          ? '100%'
          : percentage + '%';
      },
      videoThumbnail() {
        if (
          this.section.yt_preview_type &&
          this.section.yt_preview_type === 'thumbnail_image' &&
          this.section.yt_thumbnail_image
        ) {
          return this.section.yt_thumbnail_image.url;
        } else if (
          this.section.yt_preview_type &&
          this.section.yt_preview_type === 'video_preview_image'
        ) {
          const videoId = this.extractYoutubeId(this.section.yt_video_url);
          return (
            `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` ||
            require(process.env.NUXT_ENV_FALLBACK_MEDIUM_HERO_IMAGE)
          );
        } else {
          return require(process.env.NUXT_ENV_FALLBACK_MEDIUM_HERO_IMAGE);
        }
      },
      iframeUrl() {
        return this.section.yt_video_url;
      },
    },
    created() {
      this.sizeOfVideoColumn = this.section.video_width
        ? Number(this.section.video_width.split('_')[1])
        : 6;
      if (
        (this.section.yt_preview_type &&
          this.section.yt_preview_type === 'direct_video') ||
        !this.section.yt_preview_type
      ) {
        this.loadVideo();
      }
      this.isLoaded = true;
    },
    methods: {
      extractYoutubeId(url) {
        const regExp = /^.*(youtu\.be\/|v\/|embed\/|watch\?v=|v=)([^#]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
      },
      loadVideo() {
        this.isVideoLoaded = true;
      },
    },
  };
